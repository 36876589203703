import InputForm from "components/inputs/InputForm"
import Popup from "components/popups/Popup"
import Wrapper from "components/wrappers/Wrapper"
import { useState } from "react"
import { twMerge } from "tailwind-merge"

const DropDownForm = ({
  title,
  value,
  handler,
  placeholder,
  values,
  icons,
  simpleDropdown,
  disableReselect,
  className
}: {
  title?: string
  value?: any
  handler?: any
  placeholder?: string
  values?: any
  icons?: boolean
  simpleDropdown?: boolean
  disableReselect?: boolean
  className?: string
}) => {
  const [openDropDown, setOpenDropDown] = useState(false)

  const onClickDropDown = (dropdownValue: string) => {
    if (dropdownValue === value && !disableReselect) {
      handler(null)
    } else {
      handler(dropdownValue)
    }
    setOpenDropDown(false)
  }

  const getIcon = () => {
    if (icons && value) {
      return values.find((v: { text: string }) => v.text === value)?.smallIcon
    }
    return null
  }

  return (
    <div
      className={twMerge(
        "w-full relative flex justify-start items-center rounded-xl",
        className
      )}
    >
      <InputForm
        title={title}
        value={value}
        handler={onClickDropDown}
        placeholder={placeholder}
        readOnly
        notRequired
        dropdown
        dropdownValue={openDropDown}
        dropdownHandler={setOpenDropDown}
        placeholderIcon={getIcon()}
      />
      <Wrapper open={openDropDown}>
        <Popup
          values={values}
          handler={onClickDropDown}
          openHandler={setOpenDropDown}
          icons={icons}
          title={title}
          simplePopup={simpleDropdown}
        />
      </Wrapper>
    </div>
  )
}

export default DropDownForm
