import { Route, Routes } from "react-router-dom"
import { URL } from "./constants/routes"
import Footer from "components/footer/Footer"
import { isMobile } from "react-device-detect"
import PartnersLanging from "views/PartnersLanging"
import TermsOfService from "views/TermsOfService"
import ResponsibleGaming from "views/ResponsibleGaming"
import KYCPolicy from "views/KYCPolicy"

const App = () => {
  return (
    <div className="w-full min-h-screen grid grid-cols-1 bg-dark-blue">
      <div className="w-full">
        <Routes>
          <Route path={URL.HOME} element={<PartnersLanging />} />
          <Route path={URL.KYC_POLICY} element={<KYCPolicy />} />
          <Route path={URL.TOS} element={<TermsOfService />} />
          <Route
            path={URL.RESPONSIBLE_GAMING}
            element={<ResponsibleGaming />}
          />
          <Route path={URL.NOT_FOUND} element={<PartnersLanging />} />
        </Routes>
        <Footer />
      </div>
    </div>
  )
}

export default App
