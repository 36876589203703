import { ReactComponent as GOOGLEPAY } from "assets/icons/payments/gpay.svg"
import { ReactComponent as APPLEPAY } from "assets/icons/payments/apay.svg"
import { ReactComponent as MASTERCARD } from "assets/icons/payments/mastercard.svg"

import { ReactComponent as ETH } from "assets/icons/crypto/eth.svg"
import { ReactComponent as LTC } from "assets/icons/crypto/ltc.svg"
import { ReactComponent as BTC } from "assets/icons/crypto/btc.svg"

import { ReactComponent as DISCORD } from "assets/icons/media/discord.svg"
import { ReactComponent as INSTAGRAM } from "assets/icons/media/instagram.svg"
import { ReactComponent as TELEGRAM } from "assets/icons/media/telegram.svg"

import { URL } from "./routes"

export const paymentIcons = [
    {
        icon: <GOOGLEPAY className="fill-current" />,
    },
    {
        icon: <APPLEPAY className="fill-current" />,
    },
    {
        icon: <MASTERCARD className="fill-current" />,
    }
]

export const cryptoIcons = [
    {
        icon: <ETH className="fill-current h-14 w-14 lg:h-10 lg:w-10" />,
    },
    {
        icon: <LTC className="fill-current h-14 w-14 lg:h-10 lg:w-10" />,
    },
    {
        icon: <BTC className="fill-current h-14 w-14 lg:h-10 lg:w-10" />,
    },
]

export const SOCIAL = {
    DISCORD: "https://discord.com",
    INSTAGRAM: "https://www.instagram.com",
    TWITTER: "https://mobile.twitter.com",
    FACEBOOK: "https://facebook.com",
    TELEGRAM: "https://discord.com"
}


export const mediaIcons = [
    {
        icon: <DISCORD className="fill-current" />,
        link: SOCIAL.DISCORD
    },
    {
        icon: <INSTAGRAM className="fill-current" />,
        link: SOCIAL.INSTAGRAM
    },
    {
        icon: <TELEGRAM className="fill-current" />,
        link: SOCIAL.TELEGRAM
    }
]

export const HEYBETS_LINKS = {
    SITE: "https://heybets.io/",
    CASINO: "https://heybets.io/casino",
    SPORTSBOOK: "https://heybets.io/sportsbook",
    FANTASY: "https://heybets.io/fantasy-football",
    VIP: "https://heybets.io/vip",
}

export const heybetsLinks = [
    {
        text: "Casino",
        link: HEYBETS_LINKS.CASINO
    },
    {
        text: "Sportsbook",
        link: HEYBETS_LINKS.SPORTSBOOK
    },
    {
        text: "Fantasy",
        link: HEYBETS_LINKS.FANTASY
    }
    ,
    {
        text: "VIP Program",
        link: HEYBETS_LINKS.VIP
    }
]

export const tos = [
    {
        text: "Terms of Service",
        link: URL.TOS
    },
    {
        text: "KYC Policy",
        link: URL.KYC_POLICY
    },
    {
        text: "Responsible Gaming",
        link: URL.RESPONSIBLE_GAMING
    }
]
