import "./css/index.css"
import "react-loading-skeleton/dist/skeleton.css"

import React from "react"
import ReactDOM from "react-dom/client"
import { init } from "i18next"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import PropsProvider from "./contexts/PropsContext"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ScrollToTop from "utils/elements/ScrollToTop"
import { QueryClient, QueryClientProvider } from "react-query"

export const queryClient = new QueryClient()

init({
  fallbackLng: "en",
  lng: "en",
  debug: true,

  interpolation: {
    escapeValue: false
  },

  ns: ["navigation"],
  defaultNS: "navigation"
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <PropsProvider>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </PropsProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className="z-50"
        theme="dark"
      />
    </QueryClientProvider>
  </>
)
