import React from "react"

const WrapperPopup = ({ children, handler }: { children: any; handler: any }) => {
  return (
    <>
      <div
          onClick={() => handler(false)}
          className="z-20 fixed top-0 left-0 w-full h-full"
      />
      {children}
    </>
  )
}

export default WrapperPopup
