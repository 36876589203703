import React, { useState } from "react"

import Modal from "./Modal"
import { useTranslation } from "react-i18next"
import InputForm from "components/inputs/InputForm"
import { toast } from "react-toastify"
import DropDownForm from "components/dropdowns/DropDownForm"
import { ContactMethods, contactMethodValues } from "constants/contacts"
import Wrapper from "components/wrappers/Wrapper"
import FullLenghtButton from "components/buttons/FullLenghtButton"
import { createPartnersContact } from "api/affiliateApi"
import { useProps } from "contexts/PropsContext"

const ModalLetsGetInTouch = ({
  open,
  handler
}: {
  open: boolean
  handler: any
}) => {
  const { setOpenContactSoon } = useProps()
  const { t } = useTranslation(["landing"])

  const [contactName, setContactName] = useState("")
  const [contactMethod, setContactMethod] = useState("")
  const [contactValue, setContactValue] = useState("")
  const [contactPromote, setContactPromote] = useState("")

  const onEnter = async (key: string) => {
    if (key === "Enter") {
      await onSendPartnerContact()
    }
  }

  const onSendPartnerContact = async () => {
    if (!contactName) {
      return toast.error("Enter Name")
    }

    if (!contactMethod) {
      return toast.error("Choose a contact method")
    }

    if (!contactValue) {
      return toast.error("Enter contact information")
    }

    if (!contactPromote) {
      return toast.error("Enter promote information")
    }

    const result = await createPartnersContact(contactName, contactMethod, contactValue, contactPromote)
    if (result) {
      handler(false)
      setOpenContactSoon(true)
    }
  }

  const getCorrectPlacehorder = () => {
    switch (contactMethod) {
      case ContactMethods.WHATSUP:
        return t("your_whatsup")
      case ContactMethods.TELEGRAM:
        return t("your_telegram")
      case ContactMethods.SKYPE:
        return t("your_skype")
      default:
        return t("your_email")
    }
  }

  return (
    <>
      <Modal open={open} handler={handler}>
        <div className="w-screen h-screen md:h-full md:w-[400px] grid grid-cols-1 content-start justify-items-start rounded-xl bg-main-blue gap-4 px-4 py-6">
          <div className="text-14 text-white font-semibold">
            {t("contact_us")}
            <div className="h-[1px] w-full rounded-xl bg-gradient-to-r from-linear-start to-linear-stop" />
          </div>
          <InputForm
            placeholder={t("your_name")}
            value={contactName}
            handler={setContactName}
            onEnter={onEnter}
          />
          <DropDownForm
            value={contactMethod}
            handler={setContactMethod}
            placeholder={t("your_method")}
            values={contactMethodValues}
            icons
          />
          <Wrapper open={!!contactMethod}>
            <InputForm
              placeholder={getCorrectPlacehorder()}
              value={contactValue}
              handler={setContactValue}
              onEnter={onEnter}
            />
          </Wrapper>
          <InputForm
            placeholder={t("your_promote")}
            value={contactPromote}
            handler={setContactPromote}
            onEnter={onEnter}
          />
          <Wrapper open={!contactMethod}>
            <div className="h-12" />
          </Wrapper>
          <FullLenghtButton text={t("send")} handler={onSendPartnerContact} />
        </div>
      </Modal>
    </>
  )
}

export default ModalLetsGetInTouch
