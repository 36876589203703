import i18n from "language/i18n"
import React, { createContext, useEffect, useState, useRef } from "react"
import {
  getterLanguage,
  setterLanguage
} from "../storage/cookies"
import { createCtx } from "./Context"

type PropsContextType = {
  path: string
  setPath: any
  openLetsGetInTouch: boolean,
  setOpenLetsGetInTouch: any,
  aboutUsRef: any,
  ourProductsRef: any,
  whyHeybetsRef: any,
  setLocationToRef: any,
  openContactSoon: boolean,
  setOpenContactSoon: any
}

export const [useProps, CtxProvider] = createCtx<PropsContextType>()

export const PropsContext = createContext<PropsContextType | undefined>(
  undefined
)

export const PropsProvider = ({ children }: { children: React.ReactNode }) => {
  const [path, setPath] = useState(window.location.pathname)
  const [language] = useState(getterLanguage())
  const aboutUsRef = useRef(null)
  const ourProductsRef = useRef(null)
  const whyHeybetsRef = useRef(null)

  const [openLetsGetInTouch, setOpenLetsGetInTouch] = useState(false)
  const [openContactSoon, setOpenContactSoon] = useState(false)

  useEffect(() => {
    setterLanguage(language)
    i18n.changeLanguage(language)
  }, [language])

  const setLocationToRef = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  return (
    <>
      <CtxProvider
        value={{
          path,
          setPath,
          openLetsGetInTouch,
          setOpenLetsGetInTouch,
          aboutUsRef,
          ourProductsRef,
          whyHeybetsRef,
          setLocationToRef,
          openContactSoon,
          setOpenContactSoon
        }}
      >
        {children}
      </CtxProvider>
    </>
  )
}

export default PropsProvider
