import cn from "classnames"
import { KeyboardEventHandler } from "react"

const InputField = ({
  value,
  handler,
  placeholder,
  onEnter,
  readOnly,
  hidden,
  number,
  className,
  bgColor,
  maxLength,
  disabled
}: {
  value: string
  handler?: any
  placeholder?: string
  onEnter?: any
  readOnly?: boolean
  hidden?: boolean
  number?: boolean
  className?: string
  bgColor?: string
  maxLength?: number
  disabled?: boolean
}) => {
  const onValueChange = (e: any) => {
    const targetValue = e.target.value

    if (number) {
      if (!targetValue || targetValue.match(/^\d{1,}(\.\d{0,2})?$/)) {
        return handler(targetValue)
      }
    } else {
      return handler(targetValue)
    }
  }

  const onKeyUp: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (!onEnter) {
      return
    }
    onEnter(e.key, e.target)
  }

  return (
    <>
      <input
        type={hidden ? "password" : "text"}
        className={cn(
          "w-full font-bold text-white focus:outline-none text-14 placeholder-icon-text",
          bgColor || "bg-light-blue",
          className
        )}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onValueChange(e)}
        onKeyUp={onKeyUp}
        readOnly={readOnly}
        maxLength={maxLength}
        disabled={disabled}
      />
    </>
  )
}

export default InputField
