import HeybetsAffiliateImg from "assets/img/partners/heybets_affiliate.png"
import { ReactComponent as ChipIcon } from "assets/icons/partners/chip.svg"
import { ReactComponent as MoneyBagIcon } from "assets/icons/partners/moneybag.svg"
import { ReactComponent as HornIcon } from "assets/icons/partners/horn.svg"
import SendPlayers1 from "assets/img/partners/send_players_1.png"
import SendPlayers2 from "assets/img/partners/send_players_2.png"
import SendPlayers3 from "assets/img/partners/send_players_3.png"
import JoystickIcon from "assets/icons/partners/joystick.png"
import PuzleIcon from "assets/icons/partners/puzle.png"
import BigHornIcon from "assets/icons/partners/bighorn.png"
import CardsIcon from "assets/icons/partners/cards.png"
import ProcentIcon from "assets/icons/partners/procent.png"
import MoneyIcon from "assets/icons/partners/money.png"
import LetsFirstImg from "assets/img/partners/lets1.png"
import LetsSecondImg from "assets/img/partners/lets2.png"
import LetsThirdImg from "assets/img/partners/lets3.png"
import { useTranslation } from "react-i18next"
import { useProps } from "contexts/PropsContext"
import FullLenghtButton from "components/buttons/FullLenghtButton"
import WrapperPage from "components/wrappers/WrapperPage"
import { openLink } from "utils/functions"
import { SOCIAL } from "constants/nav"

const PartnersLanging = () => {
    const { setOpenLetsGetInTouch, aboutUsRef, ourProductsRef, whyHeybetsRef } = useProps()
    const { t } = useTranslation(["landing"])

    return (
        <WrapperPage fullWidth>
            <div className="w-full grid grid-cols-1 justify-items-center pt-4 lg:pt-10 gap-4 lg:gap-20 bg-main-blue">
                <div className="w-full text-end px-6 inline lg:hidden">
                    <button onClick={(e) => openLink(e, SOCIAL.HEYBETS)} className="underline text-12 font-bold text-white">
                        {t("visit_heybets")}
                    </button>
                </div>
                <div ref={aboutUsRef} className="w-full max-w-screen-xl grid grid-cols-1 lg:grid-cols-2 px-4 lg:px-0">
                    <div className="w-full grid grid-cols-1 content-center text-center lg:text-start gap-6">
                        <div className="text-24 lg:text-48 text-white font-semibold text-center lg:text-start">
                            {t("become_affiliate")}
                        </div>
                        <img src={HeybetsAffiliateImg} className="w-full inline lg:hidden" />
                        <div className="w-full grid grid-cols-1 justify-items-start text-start content-center px-4 gap-8">
                            <div className="flex justify-start items-center gap-2 lg:gap-4">
                                <div className="w-6">
                                    <ChipIcon className="fill-current" />
                                </div>
                                <div className="text-14 lg:text-16 text-icon-text font-medium">
                                    {t("become_affiliate_1")}
                                </div>
                            </div>
                            <div className="flex justify-start items-center gap-2 lg:gap-4">
                                <div className="w-6">
                                    <MoneyBagIcon className="fill-current" />
                                </div>
                                <div className="text-14 lg:text-16 text-icon-text font-medium">
                                    {t("become_affiliate_2")}
                                </div>
                            </div>
                            <div className="flex justify-start items-center gap-2 lg:gap-4">
                                <div className="w-6">
                                    <HornIcon className="fill-current" />
                                </div>
                                <div className="text-14 lg:text-16 text-icon-text font-medium">
                                    {t("become_affiliate_3")}
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-2/4">
                            <FullLenghtButton text={t("contact_us_now")} handler={() => setOpenLetsGetInTouch(true)} />
                        </div>
                    </div>
                    <div className="w-full lg:grid grid-cols-1 content-center hidden">
                        <img src={HeybetsAffiliateImg} className="w-full" />
                    </div>
                </div>
                <div ref={ourProductsRef} className="w-full max-w-screen-xl grid grid-cols-1 justify-items-center gap-8 px-4 lg:px-0 -mb-16">
                    <div className="w-full text-24 lg:text-36 font-semibold text-white text-center">
                        {t("send_players")}
                    </div>
                    <div className="w-full lg:w-2/3 text-14 lg:text-16 text-icon-text font-medium text-center">
                        {t("send_players_text")}
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 justify-items-center gap-8">
                        <div className="w-full grid grid-cols-1 gap-4 content-center">
                            <img src={SendPlayers1} className="w-full" />
                            <div className="w-full text-20 lg:text-24 text-white font-semibold lg:text-start text-center">
                                {t("casino")}
                            </div>
                            <div className="w-full text-14 text-icon-text font-regular lg:font-medium lg:text-start text-center">
                                {t("send_players_1_text")}
                            </div>
                        </div>
                        <div className="w-full grid grid-cols-1 gap-4 content-center lg:pt-10">
                            <img src={SendPlayers2} className="w-full" />
                            <div className="w-full text-20 lg:text-24 text-white font-semibold lg:text-start text-center">
                                {t("sportsbook")}
                            </div>
                            <div className="w-full text-14 text-icon-text font-regular lg:font-medium lg:text-start text-center">
                                {t("send_players_2_text")}
                            </div>
                        </div>
                        <div className="w-full grid grid-cols-1 gap-4 content-center">
                            <img src={SendPlayers3} className="w-full" />
                            <div className="w-full text-20 lg:text-24 text-white font-semibold lg:text-start text-center">
                                {t("daily_fantasy_sports")}
                            </div>
                            <div className="w-full text-14 text-icon-text font-regular lg:font-medium lg:text-start text-center">
                                {t("send_players_3_text")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full mt-10 grid grid-cols-1 content-center justify-items-center text-center bg-[url('assets/img/partners/contact_us_bg_mobile.png')] lg:bg-[url('assets/img/partners/contact_us_bg.png')] bg-cover bg-no-repeat">
                    <div className="w-full grid grid-cols-1 content-center justify-items-center text-center py-48 gap-8 px-4 lg:px-0 lg:py-44">
                        <div className="w-full lg:grid grid-cols-1 content-center justify-items-center text-center hidden">
                            <div className="w-full lg:w-2/4 text-24 font-semibold text-white text-center">
                                {t("are_you_ready")}
                            </div>
                            <div className="w-full lg:w-2/4 text-20 font-semibold text-white text-center">
                                {t("contact_us_today")}
                            </div>
                        </div>
                        <div className="w-full lg:w-2/4 text-20 font-semibold text-white text-center">
                            {t("contact_today")}
                        </div>
                        <div className="w-full text-28 lg:text-40 font-bold text-white text-center">
                            {t("contact_us_today_text")}
                        </div>
                        <div className="w-full lg:w-1/5">
                            <FullLenghtButton text={t("contact_us_now")} handler={() => setOpenLetsGetInTouch(true)} />
                        </div>
                    </div>
                </div>
                <div className="w-full  grid grid-cols-1 justify-items-center bg-[url('assets/img/partners/mountains_mobile.png')] lg:bg-[url('assets/img/partners/mountains.png')] bg-cover bg-no-repeat -mt-24 lg:-mt-16">
                    <div ref={whyHeybetsRef} className="w-full max-w-screen-xl grid grid-cols-1 justify-items-center gap-8 px-4 lg:px-0">
                        <div className="w-full text-24 lg:text-36 font-semibold text-white text-center">
                            {t("send_your_players")}
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 content-center justify-items-center gap-8">
                            <div className="w-full grid grid-cols-1 gap-4 content-start">
                                <div className="w-full flex justify-center lg:justify-start">
                                    <img src={JoystickIcon} className="w-16 h-12" />
                                </div>
                                <div className="w-full text-20 lg:text-24 text-white font-semibold lg:text-start text-center">
                                    {t("send_your_players_1")}
                                </div>
                                <div className="w-full text-14 text-icon-text font-regular lg:font-medium lg:text-start text-center">
                                    {t("send_your_players_1_text")}
                                </div>
                            </div>
                            <div className="w-full grid grid-cols-1 gap-4 content-start">
                                <div className="w-full flex justify-center lg:justify-start">
                                    <img src={PuzleIcon} className="w-16 h-12" />
                                </div>
                                <div className="w-full text-20 lg:text-24 text-white font-semibold lg:text-start text-center">
                                    {t("send_your_players_2")}
                                </div>
                                <div className="w-full text-14 text-icon-text font-regular lg:font-medium lg:text-start text-center">
                                    {t("send_your_players_2_text")}
                                </div>
                            </div>
                            <div className="w-full grid grid-cols-1 gap-4 content-start">
                                <div className="w-full flex justify-center lg:justify-start">
                                    <img src={BigHornIcon} className="w-16 h-12" />
                                </div>
                                <div className="w-full text-20 lg:text-24 text-white font-semibold lg:text-start text-center">
                                    {t("send_your_players_3")}
                                </div>
                                <div className="w-full text-14 text-icon-text font-regular lg:font-medium lg:text-start text-center">
                                    {t("send_your_players_3_text")}
                                </div>
                            </div>
                            <div className="w-full grid grid-cols-1 gap-4 content-start">
                                <div className="w-full flex justify-center lg:justify-start">
                                    <img src={CardsIcon} className="w-16 h-12" />
                                </div>
                                <div className="w-full text-20 lg:text-24 text-white font-semibold lg:text-start text-center">
                                    {t("send_your_players_4")}
                                </div>
                                <div className="w-full text-14 text-icon-text font-regular lg:font-medium lg:text-start text-center">
                                    {t("send_your_players_4_text")}
                                </div>
                            </div>
                            <div className="w-full grid grid-cols-1 gap-4 content-start">
                                <div className="w-full flex justify-center lg:justify-start">
                                    <img src={ProcentIcon} className="w-16 h-12" />
                                </div>
                                <div className="w-full text-20 lg:text-24 text-white font-semibold lg:text-start text-center">
                                    {t("send_your_players_5")}
                                </div>
                                <div className="w-full text-14 text-icon-text font-regular lg:font-medium lg:text-start text-center">
                                    {t("send_your_players_5_text")}
                                </div>
                            </div>
                            <div className="w-full grid grid-cols-1 gap-4 content-start">
                                <div className="w-full flex justify-center lg:justify-start">
                                    <img src={MoneyIcon} className="w-16 h-12" />
                                </div>
                                <div className="w-full text-20 lg:text-24 text-white font-semibold lg:text-start text-center">
                                    {t("send_your_players_6")}
                                </div>
                                <div className="w-full text-14 text-icon-text font-regular lg:font-medium lg:text-start text-center">
                                    {t("send_your_players_6_text")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full max-w-screen-xl grid grid-cols-1 content-center justify-items-center text-center gap-12 px-4 lg:px-0 py-20">
                        <div className="text-24 lg:text-36 font-semibold text-white text-center">
                            {t("lets_have_a_bear")}
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 justify-items-center gap-8">
                            <img src={LetsFirstImg} className="w-full" />
                            <img src={LetsSecondImg} className="w-full" />
                            <img src={LetsThirdImg} className="w-full" />
                        </div>
                    </div>
                </div>
            </div>
        </WrapperPage>
    )
}

export default PartnersLanging
