import React from "react"

import Modal from "./Modal"
import ThankYouImg from "assets/img/partners/thank_you.png"
import { useTranslation } from "react-i18next"

const ModalThankYou = ({
  open,
  handler,
}: {
  open: boolean
  handler: any,
}) => {
  const { t } = useTranslation(["landing"])

  return (
    <>
      <Modal open={open} handler={handler}>
        <div className="w-screen h-screen md:h-full md:max-w-screen-sm grid grid-cols-1 content-start justify-items-start gap-4 bg-main-blue rounded-xl p-4">
          <img src={ThankYouImg} className="w-full hidden lg:inline" />
          <img src={ThankYouImg} className="w-full inline lg:hidden" />
          <div className="w-full text-center text-16 text-white font-semibold">
            {t("message_back")}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalThankYou