export const clearStorage = () => {
  localStorage.clear()
}

export const removeStorage = (name: string) => {
  localStorage.removeItem(name)
}

export const getterLanguage = () => {
  return localStorage.getItem("hb-language") || ""
}

export const setterLanguage = (token: string) => {
  localStorage.setItem("hb-language", token)

  return Promise.resolve()
}
