import { ReactComponent as WhatsUpIcon } from "assets/icons/contact/whatsup.svg"
import { ReactComponent as TelegramIcon } from "assets/icons/contact/telegram.svg"
import { ReactComponent as SkypeIcon } from "assets/icons/contact/skype.svg"
import { ReactComponent as EmailIcon } from "assets/icons/contact/email.svg"

export enum ContactMethods {
    WHATSUP = "WhatsUp",
    TELEGRAM = "Telegram",
    SKYPE = "Skype",
    EMAIL = "Email"
  }

export const contactMethodValues = [
    {
        smallIcon: <WhatsUpIcon className="fill-current" />,
        text: ContactMethods.WHATSUP
    },
    {
        smallIcon: <TelegramIcon className="fill-current" />,
        text: ContactMethods.TELEGRAM
    },
    {
        smallIcon: <SkypeIcon className="fill-current" />,
        text: ContactMethods.SKYPE
    },
    {
        smallIcon: <EmailIcon className="fill-current" />,
        text: ContactMethods.EMAIL
    }
]
