import { toast } from "react-toastify"

export const parseError = (error: any, customMessage?: string) => {
  let message = ""

  console.log(error.response)

  if (error && error.response && error.response.data) {
    if (error.response.data.message) {
      message = error.response.data.message
    } else {
      message = "Something went wrong, please try again later"
    }
  }

  if (message === "Not logged in") {
    return
  }

  if (message) {
    toast.error(customMessage || message)
  }
}
