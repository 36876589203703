import { ReactComponent as Logo } from "assets/icons/logo.svg"
import { useNavigate } from "react-router-dom"
import { URL } from "constants/routes"
import ModalLetsGetInTouch from "components/modals/ModalLetsGetInTouch"
import { useProps } from "contexts/PropsContext"
import FullLenghtButton from "components/buttons/FullLenghtButton"
import { useTranslation } from "react-i18next"
import { openLink } from "utils/functions"
import { SOCIAL } from "constants/nav"
import ModalImage from "components/modals/ModalThankYou"

const NavWeb = () => {
  const {
    openLetsGetInTouch,
    setOpenLetsGetInTouch,
    aboutUsRef,
    ourProductsRef,
    whyHeybetsRef,
    setLocationToRef,
    openContactSoon,
    setOpenContactSoon
  } = useProps()
  const { t } = useTranslation(["landing"])
  const navigate = useNavigate()

  return (
    <div className={"w-screen h-16 px-4 fixed z-[110] top-0 left-0 flex justify-center items-center bg-main-blue"} >
      <div className="w-full max-w-screen-xl grid grid-cols-3 justify-items-center items-center">
        <div className="w-full">
          <button onClick={() => navigate(URL.HOME)}>
            <Logo />
          </button>
        </div>
        <div className="w-full flex justify-start items-center text-14 font-medium gap-12">
          <button onClick={() => setLocationToRef(aboutUsRef)} className="text-white">
            {t("about_us")}
          </button>
          <button onClick={() => setLocationToRef(ourProductsRef)} className="text-white">
            {t("our_products")}
          </button>
          <button onClick={() => setLocationToRef(whyHeybetsRef)} className="text-white">
            {t("why_heybets")}
          </button>
        </div>
        <div className="w-full flex justify-end items-center gap-4">
          <div className="w-2/5">
            <FullLenghtButton text={t("become_an_affiliate")} handler={() => setOpenLetsGetInTouch(true)} buttonClassname="py-2" />
          </div>
          <button onClick={(e) => openLink(e, SOCIAL.HEYBETS)} className="py-1.5 px-4 underline text-12 font-bold text-white border-2 broder-white rounded-xl">
            {t("visit_heybets")}
          </button>
        </div>
      </div>
      <ModalLetsGetInTouch open={openLetsGetInTouch} handler={setOpenLetsGetInTouch} />
      <ModalImage open={openContactSoon} handler={setOpenContactSoon} />
    </div>
  )
}

export default NavWeb
