
import WrapperPopup from "components/wrappers/WrapperPopup"
import React from "react"

const Popup = ({ values, handler, openHandler, icons, title, simplePopup }: { values: any, handler: any, openHandler?: any, icons?: boolean, title?: string, simplePopup?: boolean }) => {
    return (
        <WrapperPopup handler={openHandler}>
            <div className={
                `w-full ${values.length > 6 ? "h-48" : ""
                } absolute ${title ? "top-20" : "top-14"
                } left-0 rounded-xl grid grid-cols-1 items-center content-between bg-light-blue z-20 overflow-scroll`
            } >
                {values.map((value: any, key: number) => (
                    <button
                        key={key}
                        onClick={() => handler(simplePopup ? value : value.text)}
                        className="w-full flex justify-start items-center gap-2 hover:bg-light-purple p-3 first:rounded-t-xl last:rounded-b-xl"
                    >
                        {icons ? <div>
                            {value.smallIcon}
                        </div> : ""}
                        <div className="text-14 text-white text-start">
                            {simplePopup ? value : value.text}
                        </div>
                    </button>
                ))}
            </div>
        </WrapperPopup>
    )
}

export default Popup
