import React, { useCallback, useState } from "react"
import InputField from "./InputField"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import { useTranslation } from "react-i18next"
import { useProps } from "contexts/PropsContext"
import { toast } from "react-toastify"
import CopyToClipboard from "react-copy-to-clipboard"
import { MdContentCopy } from "react-icons/md"
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io"
import classNames from "classnames"

const InputForm = ({
  title,
  value,
  handler,
  placeholder,
  placeholderIcon,
  onEnter,
  notRequired,
  readOnly,
  password,
  number,
  dropdown,
  dropdownValue,
  dropdownHandler,
  disabled,
  inputClassNames
}: {
  title?: string
  value: string
  handler?: any
  placeholder?: string
  placeholderIcon?: any
  onEnter?: any
  notRequired?: boolean
  readOnly?: boolean
  password?: boolean
  number?: boolean
  dropdown?: boolean
  dropdownValue?: boolean
  dropdownHandler?: any
  disabled?: boolean
  inputClassNames?: string
}) => {
  const [hidden, setHidden] = useState(true)

  const handleInputClick = useCallback(() => {
    if (dropdown) {
      dropdownHandler(!dropdownValue)
    }
  }, [dropdown, dropdownValue, dropdownHandler])

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-2" onClick={handleInputClick}>
        {title ? (
          <div className="w-full flex items-center justify-start">
            <span className="text-14 font-semibold text-icon-text">
              {title}
            </span>
            {!notRequired ? (
              <span className="text-16 font-semibold text-icon-text ">*</span>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <div
          className={classNames(
            "w-full flex justify-start items-center p-3 rounded-xl bg-light-blue gap-2",
            { "opacity-40 pointer-events-none": disabled }
          )}
        >
          {placeholderIcon ? <div>{placeholderIcon}</div> : ""}
          <InputField
            value={value || ""}
            handler={handler}
            placeholder={placeholder}
            onEnter={onEnter}
            readOnly={readOnly}
            hidden={password && hidden}
            number={number}
            disabled={disabled}
            className={inputClassNames}
          />
          {password ? (
            <div
              onClick={() => setHidden(!hidden)}
              className="cursor-pointer flex items-center justify-center"
            >
              {hidden ? (
                <FaEye className="text-icon-text hover:text-white" />
              ) : (
                <FaEyeSlash className="text-icon-text hover:text-white" />
              )}
            </div>
          ) : (
            ""
          )}
          {dropdown ? (
            <div
              onClick={() => dropdownHandler(!dropdownValue)}
              className="cursor-pointer flex items-center justify-center"
            >
              {dropdownValue ? (
                <IoMdArrowDropup className="text-white text-20" />
              ) : (
                <IoMdArrowDropdown className="text-icon-text text-20" />
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  )
}

export default InputForm
