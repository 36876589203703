import React from "react"
import { isMobile } from "react-device-detect"
import NavWeb from "components/navigation/NavWeb"
import NavMobile from "components/navigation/NavMobile"

const WrapperPage = ({
    children,
    fullWidth,
}: {
    children: any,
    fullWidth?: boolean,
}) => {
    return (
        <>
            {!isMobile ? (
                <>
                    <NavWeb />
                    <div className="w-full min-h-screen flex justify-center items-start pt-16"
                    >
                        <div className={`w-full ${!fullWidth ? "py-10 px-4 xl:px-0 max-w-screen-xl" : ""} flex justify-center items-start`}>{children}</div>
                    </div>
                </>
            ) : (
                <>
                    <NavMobile />
                    <div className="w-full min-h-screen flex justify-center items-start pt-16">
                        <div className={`w-full ${!fullWidth ? "p-4" : ""} flex justify-center items-start`}>
                            {children}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default WrapperPage
