/* eslint-disable no-irregular-whitespace */
export const tosText = `
Last update: 31th October 2022

1. Introduction:
​These terms and conditions and the documents referred to below (the "Terms") apply to the use of the current website (the "Website") and its related or connected services (collectively, the "Service").
The Service and the website www.heybets.io is owned by LFG Gaming, N.V. (referred to herein as "Heybets", "We" or "Us"), a limited liability company registered in Curacao with company registration number 162024, with registered address at Zuikertuintjeweg (Zuikertuin Tower), Willemstad, Curacao (“Company”), licensed in Curaçao under Gaming Services Provider N.V. license # 365/JAZ for the provision of online games of chance.

2. Acceptance of the Terms:
Please read this Terms of Service Policy carefully to understand the rules and conditions which apply to your permission to access the website, requirments for registering an account, and usage of all services on it. These Terms and Conditions come into force as soon as you complete the registration process, which includes checking the box accepting these Terms and Conditions and successfully creating an account. By using any part of the Website following account creation, you agree to these Terms and Conditions applying to the use of the Website. These include the Terms of Service, Privacy Policy, AML Policy, Responsible Gambling Policy and KYC Policy. If you disagree with our policies, terms, or practices, you cannot create an account or use any service offered on it, disregarding if the Company or its third-party partners provide them. All website gambling and chat services are conditioned on your acceptance of these terms. You warrant not to open an account without carefully reviewing our terms and completely accepting them.

3. General Terms:
We reserve the right to revise and amend the Terms (including any documents referred to and linked to below) at any time and without advanced notice. If you object to any such changes, you must immediately stop using the Service. Your continued use of the Website following such publication will indicate your agreement to be bound by the Terms as amended. Any bets not settled prior to the changed Terms taking effect will be subject to the pre-existing Terms.

4. Your Obligations

4.1. Your age is over 18, or the legal age at which gambling, or gaming activities are allowed under the law or jurisdiction that applies to you. We reserve the right to request proof of age documents from you at any time.

4.2. You are of legal capacity and can enter into a binding legal agreement with us. You must not access the Website or utilize the Service if you are not of legal capacity.

4.3. You are a resident in a jurisdiction that allows gambling. You are not a resident of any country in which access to online gambling to its residents or to any person within such country is prohibited. It is your sole responsibility to ensure that your use of the service is legal.

4.4. You are the authorized user of the payment method you use.

4.5. You must make all payments to us in good faith and not attempt to reverse a payment made or take any action which will cause such payment to be reversed by a third party.

4.6. When placing bets you may lose some or all of your money deposited to the Service in accordance with these Terms and you will be fully responsible for that loss.

4.7. When placing bets you must not use any information obtained in breach of any legislation in force in the country in which you were when the bet was placed.

4.8. You must not either attempt to manipulate any market or element within the Service in bad faith nor in a manner that adversely affects the integrity of the Service or us.

4.9. You must generally act in good faith in relation to us of the Service at all times and for all bets made using the Service.

4.10. You, or, if applicable, your employees, employers, agents, or family members, are not registered as an Affiliate in our Affiliate program.

5. Restricted Use
5.1. You must not use the Service:

5.1.1. If you are under the age of 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you) or if you are not legally able to enter into a binding legal agreement with us or you acting as an agent for, or otherwise on behalf, of a person under 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you);
5.1.2. If you reside in a country in which access to online gambling to its residents or to any person within such country is prohibited.

5.1.3. If you are a resident of one of the following countries, or accessing the Website from one of the following countries:
 - United States of America and its territories,
 - France and its territories,
 - Netherlands and its territories and countries that form the Kingdom of Netherlands, including Bonaire, Sint Eustatius, Saba, Aruba, Curaçao and Sint Maarten,
 - Australia and its territories
 - Austria
 - Germany
 - United Kingdom of Great Britain and Northern Ireland
 - Spain

5.1.4. To collect nicknames, e-mail addresses and/or other information of other Customers by any means (for example, by sending spam, other types of unsolicited emails or the unauthorised framing of, or linking to, the Service);

5.1.5. to disrupt or unduly affect or influence the activities of other Customers or the operation of the Service generally;

5.1.6. to promote unsolicited commercial advertisements, affiliate links, and other forms of solicitation which may be removed from the Service without notice;

5.1.7. in any way which, in our reasonable opinion, could be considered as an attempt to: (i) cheat the Service or another Customer using the Service; or (ii) collude with any other Customer using the Service in order to obtain a dishonest advantage;

5.1.8. to scrape our odds or violate any of our Intellectual Property Rights; or

5.1.9. for any unlawful activity whatsoever.

5.2. You cannot sell or transfer your account to third parties, nor can you acquire a player account from a third party.

5.3. You may not, in any manner, transfer funds between player accounts.

5.4. We may immediately terminate your Account upon written notice to you if you use the Service for unauthorised purposes. We may also take legal action against you for doing so in certain circumstances.

5.5. Employees of Heybets, its licensees, distributors, wholesalers, subsidiaries, advertising, promotional or other agencies, media partners, contractors, retailers and members of the immediate families of each are NOT allowed to use the Service for real money without prior consent from the Company Director or CEO. Should such activity be discovered, the account(s) will be immediately terminated and all bonuses/winnings will be forfeited

6. Heybets Registration
6.1. To register a profile you will have to choose a username and password and provide your date of birth, and valid email address. You must enter all mandatory information requested into the registration form. It is your sole responsibility to ensure that the information you provide is true, complete and correct and update such information in the future to keep it accurate. It is your responsibility to keep your contact details up to date on your Account. Failure to do so may result in you failing to receive important Account related notifications and information from us, including changes we make to these Terms. 

6.2. We identify and communicate with our Customers via their Registered Email Address. It is the responsibility of the Customer to maintain an active and unique email account, to provide us with the correct email address and to advise us of any changes in their email address. Each Customer is wholly responsible for maintaining the security of his Registered Email Address to prevent the use of his Registered Email Address by any third party.
Heybets shall not be responsible for any damages or losses deemed or alleged to have resulted from communications between Heybets and the Customer using the Registered Email Address. Any Customer not having an email address reachable by us will have his Account suspended until such an address is provided to us. We will immediately suspend your Account upon written notice to you to this effect if you intentionally provide false or inaccurate personal information. We may also take legal action against you for doing so in certain circumstances and/or contact the relevant authorities, who may also take action against you.

6.3. Heybets don't allow you to register more than one profile. If you attempt to open more than one account on Heybets, all your accounts may get blocked, closed or suspended and your amount credited to your accounts will be frozen. If there is a good reason to create a secondary account, please contact and coordinate with the support team. This includes the use of representatives, relatives, associates, affiliates, related parties, connected persons and/or third parties operating on your behalf.

6.4. You must keep your password for the Service confidential. Provided that the Account information requested has been correctly supplied, we are entitled to assume that bets, deposits and withdrawals have been made by you. We advise you to change your password on a regular basis and never disclose it to any third party. It is your responsibility to protect your password and any failure to do so shall be at your sole risk and expense. You may log out of the Service at the end of each session. If you believe any of your Account information is being misused by a third party, or your Account has been hacked into, or your password has been discovered by a third party, you must notify us immediately. You must notify us if your Registered Email Address has been hacked into, we may, however, require you to provide additional information/ documentation so that we can verify your identity. We will immediately suspend your Account once we are aware of such an incident. In the meantime you are responsible for all activity on your Account including third party access, regardless of whether or not their access was authorised by you.

6.5. We reserve the right to refuse to accept a registration application from any applicant at our sole discretion and without any obligation to communicate a specific reason.

6.6. Before using the Service, you must personally complete the registration form and read and accept these Terms. In order to start betting on the Service or withdraw your winnings, we may require you to become a verified Customer which includes passing certain checks. You may be required to provide a valid proof of identification and any other document as it may be deemed necessary. This includes, but is not limited to, a picture ID (copy of passport, driver's licence or national ID card) and a recent utility bill listing your name and address as proof of residence. We reserve the right to suspend wagering or restrict Account options on any Account until the required information is received. This procedure is done in accordance with the applicable gaming regulation and the anti-money laundering legal requirements. Additionally, you will need to fund your Service Account using the payment methods set out on the payment section of our Website.

6.7. In order to ensure your financial worthiness and to confirm your identity, we may ask you to provide us with additional personal information, such as your name and surname, or use any third-party information providers we consider necessary. Should any additional personal information be obtained via third-party sources, we will inform you about the data obtained.

6.8. You must not at any time transmit any content or other information on the Service to another Customer or any other party by way of a screen capture (or other similar method), nor display any such information or content in a frame or in any other manner that is different from how it would appear if such Customer or third party had typed the URL for the Service into the browser line.

6.9. We are under no obligation to open an Account for you and our website sign-up page is merely an invitation to treat. It is entirely within our sole discretion whether or not to proceed with the opening of an Account for you and, should we refuse to open an Account for you, we are under no obligation to provide you with a reason for the refusal.

6.10. Upon receipt of your application, we may be in touch to request further information and/ or documentation from you in order for us to comply with our regulatory and legal obligations

7. Your Heybets Account

7.1. Accounts could use several currencies, in this case all Account balances and transactions appear in the currency used for the transaction.

7.2. We do not give credit for the use of the Service.

7.3. We may close or suspend an Account if you are not or we reasonably believe that you are not complying with these Terms, or to ensure the integrity or fairness of the Service or if we have other reasonable grounds to do so. We may not always be able to give you prior notice. If we close or suspend your Account due to you not complying with these Terms, we may cancel and/or void any of your bets and withhold any money in your account (including the deposit).

7.4. We reserve the right to close or suspend any Account without prior notice and return all funds. Contractual obligations already matured will however be honoured.

7.5. We reserve the right to refuse, restrict, cancel or limit any wager at any time for whatever reason, including any bet perceived to be placed in a fraudulent manner in order to circumvent our betting limits and/ or our system regulations.

7.6. If any amount is mistakenly credited to your Account it remains our property and when we become aware of any such mistake, we shall notify you and the amount will be withdrawn from your Account.

7.7. If, for any reason, your Account goes overdrawn, you shall be in debt to us for the amount overdrawn.

7.8. You have to directly contact us to inform us if you notice any errors with respect to your account or any calculations with respect to any bet you have placed. We reserve the right to declare null and void any bets that are subject to such an error.

7.9. Please remember that betting is purely for entertainment and pleasure and you should stop as soon as it stops being fun. Absolutely do not bet anything you can’t afford to lose. If you feel that you may have lost control of your gambling, we offer a self-exclusion option. Just send a message to our Customer Support Department using your Registered Email Address that you wish to SELF-EXCLUDE and this request will take effect within 24 hours from the moment of its receipt. In this case your account will be disabled until your further notice, and you won’t be able to login to it.

7.10. You cannot transfer, sell, or pledge Your Account to another person. This prohibition includes the transfer of any assets of value of any kind, including but not limited to ownership of accounts, winnings, deposits, bets, rights and/or claims in connection with these assets, legal, commercial or otherwise. The prohibition on said transfers also includes however is not limited to the encumbrance, pledging, assigning, usufruct, trading, brokering, hypothecation and/or gifting in cooperation with a fiduciary or any other third party, company, natural or legal individual, foundation and/or association in any way shape or form

7.11. Should you wish to close your account with us, please send an email from your Registered Email Address to our Customer Support Department via the links on the Website.

8. Inactive Accounts

8.1. We will charge you a fee of €5 (or currency equivalent) per calendar month (Inactive Account Fee) if:
you have not logged into your Account or logged out from your Account, or used the Service, or made any bet in any section of the Service for twelve consecutive months or more (Account Activity);
and your Account is in credit.

8.2. You will be notified that we will start charging you the fees on the eleventh month of inactivity and you will always have the option to log in and withdraw your funds.

8.3. The Inactive Account Fee will be withdrawn from your Account on a monthly basis to the extent that your Account remains in credit and only for the period during which there remains no Account Activity after the initial twelve month period has passed. If the Inactive Account Fee is due to be withdrawn from your Account and the balance of your Account is less than €5 (or equivalent currency), the Inactive Account Fee shall comprise the remaining funds in your Account.

9 .Deposit of Funds

9.1. All deposits should be made from an account or payment system or credit card that is registered in your own name, and any deposits made in any other currency will be converted using the exchange rate obtained from cryptochill.com, or at our own bank's or our payment processor’s prevailing rate of exchange following which your Account will be deposited accordingly. Note that some payment systems may apply additional currency exchange fees which will be deducted from the sum of your deposit.

9.2. Fees and charges may apply to customer deposits and withdrawals, which can be found on the Website. In most cases we absorb transaction fees for deposits to your heybets.io Account. You are responsible for your own bank charges that you may incur due to depositing funds with us.

9.3. Company is not a financial institution and uses a third party electronic payment processors to process credit and debit card deposits; they are not processed directly by us. If you deposit funds by either a credit card or a debit card, your Account will only be credited if we receive an approval and authorisation code from the payment issuing institution. If your card issuer gives no such authorisation, your Account will not be credited with those funds.

9.4. You agree to fully pay any and all payments and charges due to us or to payment providers in connection with your use of the Service. You further agree not to make any charge-backs or renounce or cancel or otherwise reverse any of your deposits, and in any such event you will refund and compensate us for such unpaid deposits including any expenses incurred by us in the process of collecting your deposit, and you agree that any winnings from wagers utilising those charged back funds will be forfeited. You acknowledge and agree that your player account is not a bank account and is therefore not guaranteed, insured or otherwise protected by any deposit or banking insurance system or by any other similar insurance system of any other jurisdiction, including but not limited to your local jurisdiction. Furthermore, the player account does not bear interest on any of the funds held in it.

9.5. If you decide to accept any of our promotional or bonus offer by entering a bonus code during deposit, you agree to the Terms of Bonuses and terms of each specific bonus.

9.6. Funds originating from criminal and/or illegal and/or unauthorized activities must not be deposited with us.

9.7. If you deposit using your credit card, it is recommended that you retain a copy of Transaction Records and a copy of these Terms.

9.8. Internet Gambling may be illegal in the jurisdiction in which you are located; if so, you are not authorized to use your payment card to deposit on this site. It is your responsibility to know the laws concerning online gambling in your country of domicile.

10. Withdrawal of Funds

10.1. You may withdraw any unutilized and cleared funds held in your player account by submitting a withdrawal request in accordance with our withdrawal conditions. The minimum withdrawal amount per transaction is € 10 (or equivalent in other currency) with the exception of an account closure in which case you may withdraw the full balance.

10.2. There are no withdrawal commissions if you roll over (wager) the deposit at least 1 time. Otherwise we are entitled to deduct a 8% fee with minimum sum of 4 euro (or equivalent in your account currency) in order to combat money laundering.

10.3. We reserve the right to request photo ID, address confirmation or perform additional verification procedures (request your selfie, arrange a verification call etc.) for the purpose of identity verification prior to granting any withdrawals from your Account. We also reserve our rights to perform identity verification at any time during the lifetime of your relationship with us.

10.4. All withdrawals must be made to the original debit, credit card, bank account, method of payment used to make the payment to your Account. We may, and always at our own discretion, allow you to withdraw to a payment method from which your original deposit did not originate. This will always be subject to additional security checks.

10.5. Should you wish to withdraw funds but your account is either inaccessible, dormant, locked or closed, please contact our Customer Service Department.

10.6. In cases when your balance is at least 10 times larger than the total sum of your deposits, you will be limited to € 5,000 (or currency equivalent) for withdrawal per month. In other cases the maximum withdrawal amount per month is € 10,000.

10.7. Please note that we cannot guarantee successful processing of withdrawals or refunds in the event if you breach the Restricted use policy stated in Clauses 4.3 and 5.

11. Payment Transactions and Processors

11.1. You are fully responsible for paying all monies owed to us. You must make all payments to us in good faith and not attempt to reverse a payment made or take any action which will cause such payment to be reversed by a third party in order to avoid a liability legitimately incurred. You will reimburse us for any charge-backs, denial or reversal of payment you make and any loss suffered by us as a consequence thereof. We reserve the right to also impose an administration fee of €50, or currency equivalent per charge-back, denial or reversal of payment you make.

11.2. We reserve the right to use third party electronic payment processors and or merchant banks to process payments made by you and you agree to be bound by their terms and conditions providing they are made aware to you and those terms do not conflict with these Terms.

11.3. All transactions made on our site might be checked to prevent money laundering or terrorism financing activity. Suspicious transactions will be reported to the relevant authority.

12. Errors

12.1. In the event of an error or malfunction of our system or processes, all bets are rendered void. You are under an obligation to inform us immediately as soon as you become aware of any error with the Service. In the event of communication or system errors or bugs or viruses occurring in connection with the Service and/or payments made to you as a result of a defect or error in the Service, we will not be liable to you or to any third party for any direct or indirect costs, expenses, losses or claims arising or resulting from such errors, and we reserve the right to void all games/bets in question and take any other action to correct such errors.

12.2. We make every effort to ensure that we do not make errors in posting bookmaker lines. However, if as a result of human error or system problems a bet is accepted at an odd that is: materially different from those available in the general market at the time the bet was made; or clearly incorrect given the chance of the event occurring at the time the bet was made then we reserve the right to cancel or void that wager, or to cancel or void a wager made after an event has started.

12.3. We have the right to recover from you any amount overpaid and to adjust your Account to rectify any mistake. An example of such a mistake might be where a price is incorrect or where we enter a result of an event incorrectly. If there are insufficient funds in your Account, we may demand that you pay us the relevant outstanding amount relating to any erroneous bets or wagers. Accordingly, we reserve the right to cancel, reduce or delete any pending plays, whether placed with funds resulting from the error or not.

13. Rules of Play, refunds and cancellations

13.1. The winner of an event will be determined on the date of the event's settlement, and we will not recognize protested or overturned decisions for wagering purposes.

13.2. All results posted shall be final after 72 hours and no queries will be entertained after that period of time. Within 72 hours after results are posted, we will only reset/correct the results due to human error, system error or mistakes made by the referring results source.

13.3. If a match result is overturned for any reason by the governing body of the match within the payout period then all money will be refunded.

13.4. If a draw occurs in a game where a draw option is offered all stakes on a team win or lose will be lost. If a draw option is not offered then everyone receives a refund in the outcome of a draw on the match. And if a draw option has not been made available, then extra time will count, if played.

13.5. If a result cannot be validated by us, for instance if the feed broadcasting the event is interrupted (and cannot be verified by another source) then at our election, the wagers on that event will be deemed invalid and wagers refunded.

13.6. Minimum and maximum wager amounts on all events will be determined by us and are subject to change without prior written notice. We also reserve the right to adjust limits on individual Accounts as well.

13.7. Customers are solely responsible for their own Account transactions. Once a transaction is complete, it cannot be changed. We do not take responsibility for missing or duplicate wagers made by the Customer and will not entertain discrepancy requests because a play is missing or duplicated. Customers may review their transactions in the My Account section of the site after each session to ensure all requested wagers were accepted.
13.8. A matchup will have action as long as the two teams are correct, and regardless of the League header in which it is placed on our Website.

13.9. The start dates and times displayed on the Website for eSport matches are an indication only and are not guaranteed to be correct. If a match is suspended or postponed, and not resumed within 72 hours from the actual scheduled start time, the match will have no action and wagers will be refunded. The exception being any wager on whether a team/player advances in a tournament, or wins the tournament, will have action regardless of a suspended or postponed match.

13.10. If an event is posted by us with an incorrect date, all wagers have action based on the date announced by the governing body.

13.11. If a team is using stand-ins, the result is still valid as it was the team's choice to use the stand-ins.

13.12. Company reserves the right to remove events, markets and any other products from the Website.

14. Communications and Notices

14.1. All communications and notices to be given under these Terms by you to us shall be sent using a Customer Support form on the Website.

14.2. All communications and notices to be given under these Terms by us to you shall, unless otherwise specified in these Terms, be either posted on the Website and/or sent to the Registered Email Address we hold on our system for the relevant Customer. The method of such communication shall be in our sole and exclusive discretion.

14.3. All communications and notices to be given under these Terms by either you or us shall be in writing in the English language and must be given to and from the Registered Email Address in your Account.

14.4. From time to time, we may contact you by email for the purpose of offering you information about betting, unique promotional offerings, and other information from heybets.io. You agree to receive such emails when you agree to these Terms when registering at the Website. You can choose to opt out of receiving such promotional offerings from us at any time by submitting a request to the Customer Support.

15. Matters Beyond Our Control

We cannot be held liable for any failure or delay in providing the Service due to an event of Force Majeure which could reasonably be considered to be outside our control despite our execution of reasonable preventative measures such as: an act of God; trade or labour dispute; power cut; act, failure or omission of any government or authority; obstruction or failure of telecommunication services; or any other delay or failure caused by a third party, and we will not be liable for any resulting loss or damage that you may suffer. In such an event, we reserve the right to cancel or suspend the Service without incurring any liability.
Liability

16.1. TO THE EXTENT PERMITTED BY APPLICABLE LAW, WE WILL NOT COMPENSATE YOU FOR ANY REASONABLY FORESEEABLE LOSS OR DAMAGE (EITHER DIRECT OR INDIRECT) YOU MAY SUFFER IF WE FAIL TO CARRY OUT OUR OBLIGATIONS UNDER THESE TERMS UNLESS WE BREACH ANY DUTIES IMPOSED ON US BY LAW (INCLUDING IF WE CAUSE DEATH OR PERSONAL INJURY BY OUR NEGLIGENCE) IN WHICH CASE WE SHALL NOT BE LIABLE TO YOU IF THAT FAILURE IS ATTRIBUTED TO: (I) YOUR OWN FAULT; (II) A THIRD PARTY UNCONNECTED WITH OUR PERFORMANCE OF THESE TERMS (FOR INSTANCE PROBLEMS DUE TO COMMUNICATIONS NETWORK PERFORMANCE, CONGESTION, AND CONNECTIVITY OR THE PERFORMANCE OF YOUR COMPUTER EQUIPMENT); OR (III) ANY OTHER EVENTS WHICH NEITHER WE NOR OUR SUPPLIERS COULD HAVE FORESEEN OR FORESTALLED EVEN IF WE OR THEY HAD TAKEN REASONABLE CARE. AS THIS SERVICE IS FOR CONSUMER USE ONLY WE WILL NOT BE LIABLE FOR ANY BUSINESS LOSSES OF ANY KIND.

16.2. IN THE EVENT THAT WE ARE HELD LIABLE FOR ANY EVENT UNDER THESE TERMS, OUR TOTAL AGGREGATE LIABILITY TO YOU UNDER OR IN CONNECTION WITH THESE TERMS SHALL NOT EXCEED (A) THE VALUE OF THE BETS AND OR WAGERS YOU PLACED VIA YOUR ACCOUNT IN RESPECT OF THE RELEVANT BET/WAGER OR PRODUCT THAT GAVE RISE TO THE RELEVANT LIABILITY, OR (B) EUR €500 IN AGGREGATE, WHICHEVER IS LOWER.

16.3. WE STRONGLY RECOMMEND THAT YOU (I) TAKE CARE TO VERIFY THE SUITABILITY AND COMPATIBILITY OF THE SERVICE WITH YOUR OWN COMPUTER EQUIPMENT PRIOR TO USE; AND (II) TAKE REASONABLE PRECAUTIONS TO PROTECT YOURSELF AGAINST HARMFUL PROGRAMS OR DEVICES INCLUDING THROUGH INSTALLATION OF ANTI-VIRUS SOFTWARE.

17. Gambling By Those Under Age

17.1. If we suspect that you are or receive notification that you are currently under 18 years or were under 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you) when you placed any bets through the Service your Account will be suspended (locked) to prevent you placing any further bets or making any withdrawals from your Account. We will then investigate the matter, including whether you have been betting as an agent for, or otherwise on behalf, of a person under 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you). If having found that you: (a) are currently; (b) were under 18 years or below the majority age which applies to you at the relevant time; or (c) have been betting as an agent for or at the behest of a person under 18 years or below the majority age which applies:
all winnings currently or due to be credited to your Account will be retained;
all winnings gained from betting through the Service whilst under age must be paid to us on demand (if you fail to comply with this provision we will seek to recover all costs associated with recovery of such sums); and/or
any monies deposited in your Account which are not winnings will be returned to you OR retained until you turn 18 years old at our sole discretion. We reserve the right to deduct payment transaction fees from the amount to return, including transaction fees for deposits to your <yourwebsite.com> account which we covered.

17.2. This condition also applies to you if you are over the age of 18 years but you are placing your bets within a jurisdiction which specifies a higher age than 18 years for legal betting and you are below that legal minimum age in that jurisdiction.

17.3. In the event we suspect you are in breach of the provisions of this Clause or are attempting to rely on them for a fraudulent purpose, we reserve the right to take any action necessary in order to investigate the matter, including informing the relevant law enforcement agencies.

18. Fraud

We will seek criminal and contractual sanctions against any Customer involved in fraud, dishonesty or criminal acts. We will withhold payment to any Customer where any of these are suspected. The Customer shall indemnify and shall be liable to pay to us on demand all costs, charges or losses sustained or incurred by us (including any direct, indirect or consequential losses, loss of profit, loss of business and loss of reputation) arising directly or indirectly from the Customer’s fraud, dishonesty or criminal act.

19. Intellectual Property

19.1. Any unauthorised use of our name and logo may result in legal action being taken against you.

19.2. As between us and you, we are the sole owners of the rights in and to the Service, our technology, software and business systems (the "Systems") as well as our odds.
you must not use your personal profile for your own commercial gain (such as selling your status update to an advertiser); and
when selecting a nickname for your Account we reserve the right to remove or reclaim it if we believe it appropriate.

19.3. You may not use our URL, trademarks, trade names and/or trade dress, logos ("Marks") and/or our odds in connection with any product or service that is not ours, that in any manner is likely to cause confusion among Customers or in the public or that in any manner disparages us.

19.4. Except as expressly provided in these Terms, we and our licensors do not grant you any express or implied rights, license, title or interest in or to the Systems or the Marks and all such rights, license, title and interest specifically retained by us and our licensors. You agree not to use any automatic or manual device to monitor or copy web pages or content within the Service. Any unauthorized use or reproduction may result in legal action being taken against you.

20. Your License

20.1. Subject to these Terms and your compliance with them, we grant to you a non-exclusive, limited, non transferable and non sub-licensable license to access and use the Service for your personal non-commercial purposes only. Our license to you terminates if our agreement with you under these Terms ends.

19.2. Save in respect of your own content, you may not under any circumstances modify, publish, transmit, transfer, sell, reproduce, upload, post, distribute, perform, display, create derivative works from, or in any other manner exploit, the Service and/or any of the content thereon or the software contained therein, except as we expressly permit in these Terms or otherwise on the Website. No information or content on the Service or made available to you in connection with the Service may be modified or altered, merged with other data or published in any form including for example screen or database scraping and any other activity intended to collect, store, reorganise or manipulate such information or content.

20.3. Any non-compliance by you with this Clause may also be a violation of our or third parties' intellectual property and other proprietary rights which may subject you to civil liability and/or criminal prosecution.

21. Your Conduct and Safety

21.1. For your protection and protection of all our Customers, the posting of any content on the Service, as well as conduct in connection therewith and/or the Service, which is in any way unlawful, inappropriate or undesirable is strictly prohibited (“Prohibited Behaviour”).

21.2. If you engage in Prohibited Behaviour, or we determine in our sole discretion that you are engaging in Prohibited Behaviour, your Account and/or your access to or use of the Service may be terminated immediately without notice to you. Legal action may be taken against you by another Customer, other third party, enforcement authorities and/or us with respect to you having engaged in Prohibited Behaviour.

21.3. Prohibited Behaviour includes, but is not limited to, accessing or using the Service to:
promote or share information that you know is false, misleading or unlawful;
conduct any unlawful or illegal activity, such as, but not limited to, any activity that furthers or promotes any criminal activity or enterprise, violates another Customer's or any other third party’s privacy or other rights or that creates or spreads computer viruses;
harm minors in any way;
transmit or make available any content that is unlawful, harmful, threatening, abusive, tortuous, defamatory, vulgar, obscene, lewd, violent, hateful, or racially or ethnically or otherwise objectionable;
transmit or make available any content that the user does not have a right to make available under any law or contractual or fiduciary relationship, including without limitation, any content that infringes a third party’s copyright, trademark or other intellectual property and proprietary rights;
transmit or make available any content or material that contains any software virus or other computer or programming code (including HTML) designed to interrupt, destroy or alter the functionality of the Service, its presentation or any other website, computer software or hardware;
interfere with, disrupt or reverse engineer the Service in any manner, including, without limitation, intercepting, emulating or redirecting the communication protocols used by us, creating or using cheats, mods or hacks or any other software designed to modify the Service, or using any software that intercepts or collects information from or through the Service;
retrieve or index any information from the Service using any robot, spider or other automated mechanism;
participate in any activity or action that, in the sole and entire unfettered discretion of us results or may result in another Customer being defrauded or scammed;
transmit or make available any unsolicited or unauthorised advertising or mass mailing such as, but not limited to, junk mail, instant messaging, "spim", "spam", chain letters, pyramid schemes or other forms of solicitations;
create Accounts on the Website by automated means or under false or fraudulent pretences;
impersonate another Customer or any other third party, or
any other act or thing done that we reasonably consider to be contrary to our business principles.
The above list of Prohibited Behaviour is not exhaustive and may be modified by us at any time or from time to time. We reserve the right to investigate and to take all such actions as we in our sole discretion deem appropriate or necessary under the circumstances, including without limitation deleting the Customer’s posting(s) from the Service and/or terminating their Account, and take any action against any Customer or third party who directly or indirectly in, or knowingly permits any third party to directly or indirectly engage in Prohibited Behaviour, with or without notice to such Customer or third party.

22. Links to Other Websites

The Service may contain links to third party websites that are not maintained by, or related to, us, and over which we have no control. Links to such websites are provided solely as a convenience to Customers, and are in no way investigated, monitored or checked for accuracy or completeness by us. Links to such websites do not imply any endorsement by us of, and/or any affiliation with, the linked websites or their content or their owner(s). We have no control over or responsibility for the availability nor their accuracy, completeness, accessibility and usefulness. Accordingly when accessing such websites we recommend that you should take the usual precautions when visiting a new website including reviewing their privacy policy and terms of use.

23. Complaints

23.1. If you have any concerns or questions regarding these Terms you should contact our Customer Service Department via the links on the Website and use your Registered Email Address in all communication with us.

23.2. NOTWITHSTANDING THE FOREGOING, WE TAKE NO LIABILITY WHATSOEVER TO YOU OR TO ANY THIRD PARTY WHEN RESPONDING TO ANY COMPLAINT THAT WE RECEIVED OR TOOK ACTION IN CONNECTION THEREWITH.

23.3. If a Customer is not satisfied with how a bet has been settled then the Customer should provide details of their grievance to our Customer Service Department. We shall use our reasonable endeavours to respond to queries of this nature within a few days (and in any event we intend to respond to all such queries within 28 days of receipt).

23.4. Disputes must be lodged within three (3) days from the date the wager in question has been decided. No claims will be honoured after this period. The Customer is solely responsible for their Account transactions.

23.5. In the event of a dispute arising between you and us our Customer Service Department will attempt to reach an agreed solution. Should our Customer Service Department be unable to reach an agreed solution with you, the matter will be escalated to our management.

23.6. Should all efforts to resolve a dispute to the Customer's satisfaction have failed, the Customer has the right to lodge a complaint with our licensing body Gaming Services Provider N.V.  

24. Assignment

Neither these Terms nor any of the rights or obligations hereunder may be assigned by you without the prior written consent of us, which consent will not be unreasonably withheld. We may, without your consent, assign all or any portion of our rights and obligations hereunder to any third party provided such third party is able to provide a service of substantially similar quality to the Service by posting written notice to this effect on the Service.

25. Severability

In the event that any provision of these Terms is deemed by any competent authority to be unenforceable or invalid, the relevant provision shall be modified to allow it to be enforced in line with the intention of the original text to the fullest extent permitted by applicable law. The validity and enforceability of the remaining provisions of these Terms shall not be affected.

26. Breach of These Terms

Without limiting our other remedies, we may suspend or terminate your Account and refuse to continue to provide you with the Service, in either case without giving you prior notice, if, in our reasonable opinion, you breach any material term of these Terms. Notice of any such action taken will, however, be promptly provided to you.

27. General Provisions

27.1. Term of agreement. These Terms shall remain in full force and effect while you access or use the Service or are a Customer or visitor of the Website. These Terms will survive the termination of your Account for any reason.

27.2. Gender. Words importing the singular number shall include the plural and vice versa, words importing the masculine gender shall include the feminine and neuter genders and vice versa and words importing persons shall include individuals, partnerships, associations, trusts, unincorporated organisations and corporations.
27.3. Waiver. No waiver by us, whether by conduct or otherwise, of a breach or threatened breach by you of any term or condition of these Terms shall be effective against, or binding upon, us unless made in writing and duly signed by us, and, unless otherwise provided in the written waiver, shall be limited to the specific breach waived. The failure of us to enforce at any time any term or condition of these Terms shall not be construed to be a waiver of such provision or of the right of us to enforce such provision at any other time.

27.4. Acknowledgement. By hereafter accessing or using the Service, you acknowledge having read, understood and agreed to each and every paragraph of these Terms. As a result, you hereby irrevocably waive any future argument, claim, demand or proceeding to the contrary of anything contained in these Terms.

27.5. Language. In the event of there being a discrepancy between the English language version of these rules and any other language version, the English language version will be deemed to be correct.

27.6. Governing Law. These Terms are governed by the law in force in Curaçao.

27.7. Entire agreement. These Terms constitute the entire agreement between you and us with respect to your access to and use of the Service, and supersedes all other prior agreements and communications, whether oral or written with respect to the subject matter hereof.
`

export const responsibleGamingText = `
Last updated: 31th October 2022

Please read this information carefully for your own benefit. 
heybets.io is operated by LFG Gaming N.V. having its office at Zuikertuintjeweg (Zuikertuin Tower), Willemstad, Curacao. 
Company Registration number 162024.

Interpretation
The words of which the initial letter is capitalized have meanings defined under the following conditions.
The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.

Definitions
For the purposes of these Terms and Conditions:
 - Account means a unique account created for You to access our Service or parts of our Service.
 - Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Curacao Co
 - Service refers to the Website.
 - Website refers to <Website.com>.
 - You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.

Responsible Gambling and Self Exclusion
Gambling means for the majority of our Users, entertainment, fun and excitement. But we also know that for some of our Users gambling has negative side effects. In the medical science is pathologic gambling since many years as serious sickness recognised. 
Since our first day we think about this problematic and try out best to help. Under “Responsible Gambling” We understand multiple steps of measures, with which a gambling provider can help to lower the possibility of negative side effects appearing. -In case they already appear we also try to take active steps against them. 
The most important instrument against negative side effects from gambling are knowledge and education about the risks of gambling too support our Users self-control in order to make sure they do not suffer from negative side effects.  

Information and contact 
Our Support will help you via email at all time without any additional costs for you: 
 - email: support@heybets.io

Our Support will of course not give out any information about You without Your consent to anyone else 
In addition you also can take a self-test, if You are already gambling addicted at: https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/
And you can also find additional information about gambling addictions at: https://www.begambleaware.org/safer-gambling/

Helpful hints for responsible gambling at heybets.io
We recommend you think about the following hints, before gambling in order to insure gambling stays fun for You and without any negative side effects: 

 - Set yourself a deposit limit
    Before you start to gambling think about how much you can afford to gamble with according to Your financial situation. Play with amounts which are for fun and for Your entertainment
 - Do not try to win back a loss at every cost
    Try to not take to huge risks to win back what You lost before at any cost. Play for Entertainment and not to earn money.
 - Set yourself a time limit
    Set yourself a time limit and do not break it. Keep in mind gambling should stay in balance with your other hobbies and not be Your only hobby. 
 - Play smart:
    It is smarter to not play, when You are extremely stressed, depressed or under to much pressure. Also do not play when you are under the influence of Medications, Drugs or Alcohol. 
 - Take breaks:
    You should take breaks when You notice, that You get tired or can´t concentrate anymore
 - Only one account:
    To make it easier to have an overview how much time and money You spend on gambling it is highly advised to not create more than one Account per Person. 

Minor Protection 
To use our Service, You have to be 18 years or older. To avoid abuse, keep Your login data save from any minors near You.  
Principally we recommend a filter program to avoid minors, especially children, to access any context on the internet, which is not healthy for them.
For parents we can recommend a list of internet filters, to support them, from keeping their children from any context, which was not made for them: 
https://famisafe.wondershare.com/internet-filter/best-internet-filters.html

Self-Exclusion:
In case You are diagnosed with a gambling addiction or try to stay away from gambling for a different reason, we want to assist you to stay away from anything, that does nothing good for you. “Self-Exclusion” means, that You exclude yourself, out of Your own choice, from all gambling services. This exclusion cannot be undone for a set amount of time. If you wish to self-exclude yourself from gambling, please message our support and give them a time span between 6 months and 5 years. They also will explain you all future steps and what is needed from you.
 - email: support@heybets.io

Please keep in mind that Self Exclusion is permanent for the set time span and will not be undone for your own protection. 
During Self Exclusion you are not allowed to create a new Account and every attempt to create a new Account during Self Exclusion is a violation of our Terms of Service and may result in the permanent ban of your original account.
`

export const kycPolicyText = `
Last updated: 31th October 2022

When a user makes an aggregate lifetime total of deposits exceeding EUR 2000 or requests a withdrawal of any amount inside the heybets.io Platform, then it is compulsory for them to perform a full KYC process.  
During this process, the user will have to input some basic details about themselves and then upload

  1. A copy of Government Issued Photo ID (in some cases front and back depending on the doc)
  2. A selfie of themselves holding the ID doc
  3. A bank statement/Utility Bill

Once uploaded, the user will get a “Temporarily Approved” Status and the documents will now be on our side, and the “KYC Team” will have 24hrs to go over them and email the user about the outcome:
  - Approval
  - Rejection
  - More information needed – No change in Status

When the user is on “Temporarily Approved” Status then 
  - They can use the platform normally 
  - They cannot deposit more than EUR 500 in aggregate total
  - They cannot complete any withdrawal. 

Guideline for the “KYC Process”
  1. Proof of ID
    a. Signature Is there
    b. Country is not a Restricted Country: United States of America and its territories, France and its territories, Netherlands and its territories and countries that form the Kingdom of Netherlands including Bonaire, Sint Eustatius, Saba, Aruba, Curacao and Sint Maarten, Australia and its territories, United Kingdom of Great Britain, Northern Ireland, Spain. 
    c. Full Name matches client’s name
    d. Document does not expire in the next 3 months
    e. Owner is over 18 years of age
  2. Proof of Residence
    a. Bank Statement or Utility Bill
    b. Country is not a Restricted Country: United States of America and its territories, France and its territories, Netherlands and its territories and countries that form the Kingdom of Netherlands including Bonaire, Sint Eustatius, Saba, Aruba, Curacao and Sint Maarten, Australia and its territories, United Kingdom of Great Britain, Northern Ireland, Austria, Germany, Spain, and Cyprus. 
    c. Full Name matches client’s name and is same as in proof of ID.
    d. Date of Issue: In the last 3 months
  3. Selfie with ID
    a. Holder is the same as in the ID document above
    b. ID document is the same as in “1”. Make sure photo/ID number is the same


Notes on the “KYC Process”
 1. When the KYC process is unsuccessful then the reason is documented and a support ticket is created in the system. The ticket number along with an explanation is communicated back to the user. 
 2. Once all proper documents are in our possession then the account gets approved. 

“Other AML measures”

  1. If a user has not passed full KYC then they cannot make additional deposits or withdrawals of any amount. 
  2. If a user has passed the KYC process successfully then
    a. There is a deposit limit per transaction (max EUR 2,000)
    b. Prior to any withdrawal there is a detailed algorithmic and manual check on the activity and balance of the user to see if the amount withdrawn is a result of proper activity in the platform. 
  3. Under no circumstances may a user transfer funds directly to another user. 
`
