import { animated, useTransition } from "@react-spring/web"
import React, { useEffect } from "react"
import { AiOutlineClose } from "react-icons/ai"
import cn from "classnames"
import ModalBg from "./ModalBg"

const Modal = ({
  children,
  open,
  handler,
  modalPosition,
  modalContentStyles,
  closeButtonStyles
}: {
  children: any
  open: boolean
  handler: any
  modalPosition?: string
  modalContentStyles?: string
  closeButtonStyles?: string
}) => {
  const transition = useTransition(open, {
    config: {
      mass: 1,
      tension: 600,
      friction: 40,
      clamp: true,
      precision: 0.01,
      velocity: 0
    },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [open])

  return (
    <>
      {transition((style: any, item: boolean) => {
        return item ? (
          <animated.div
            style={style}
            className={`fixed top-0 left-0 z-30 flex h-screen w-screen min-w-[300px] ${
              modalPosition || "items-center"
            }`}
          >
            <div className="flex justify-center w-full max-h-full overflow-y-auto md:rounded-4">
              <ModalBg handler={handler} />
              <div
                className={cn("relative z-20 max-w-full", modalContentStyles)}
              >
                <button
                  onClick={() => handler(false)}
                  className={cn("absolute top-0 sm:top-4 right-1 sm:right-4 p-2 rounded-full", closeButtonStyles)}
                >
                  <AiOutlineClose className="text-20 text-icon-text hover:text-white" />
                </button>
                {children}
              </div>
            </div>
          </animated.div>
        ) : (
          <></>
        )
      })}
    </>
  )
}

export default Modal
