import { ReactComponent as COPYRIGHT } from "assets/icons/copyright/copyright.svg"
import { ReactComponent as Logo } from "assets/icons/logohuge.svg"
import { cryptoIcons, heybetsLinks, HEYBETS_LINKS, mediaIcons, tos } from "constants/footer"
import { URL } from "constants/routes"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { openLink } from "utils/functions"

const Footer = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(["landing"])

  return (
    <div className="w-full flex justify-center items-center gap-8 bg-main-blue py-8 px-14 lg:px-0">
      <div className="w-full max-w-screen-xl grid grid-cols-1 lg:grid-cols-5 content-start justify-items-center gap-6">
        <div className="w-full grid grid-cols-1 content-start justify-items-center lg:justify-items-start gap-4">
          <button onClick={() => navigate(URL.HOME)}>
            <Logo className="fill-current" />
          </button>
          <div className="text-8 text-icon-text hidden lg:inline">
            <span onClick={(e) => openLink(e, HEYBETS_LINKS.SITE)} className="text-8 font-semibold text-icon-text underline cursor-pointer">
              Heybets.io
            </span>
            {" "}{t("is_owned")}
          </div>
        </div>
        <div className="w-full grid grid-cols-1 content-start justify-items-center lg:justify-items-start gap-4 lg:pl-10">
          <div className="text-12 text-icon-text text-center lg:text-start font-semibold">
            {t("heybets_supports")}
          </div>
          <div className="flex justify-start items-start gap-6">
            {cryptoIcons.map((ci, key: number) => (
              <div key={key}>
                {ci.icon}
              </div>
            ))}
          </div>
        </div>
        <div className="w-full flex justify-center lg:col-span-2 gap-2 lg:px-0">
          <div className="w-full flex justify-start">
            <div className="grid grid-cols-1 justify-items-start gap-2 lg:pl-20">
              <div className="text-14 text-icon-text font-semibold">
                {t("heybets")}
              </div>
              {heybetsLinks.map((t, key: number) => (
                <button
                  key={key}
                  onClick={(e) => t.link && openLink(e, t.link)}
                  className="text-12 text-icon-text font-semibold hover:text-white">
                  {t.text}
                </button>
              ))}
            </div>
          </div>
          <div className="w-full flex justify-end lg:justify-start">
            <div className="grid grid-cols-1 justify-items-start content-start gap-2 lg:pl-10">
              <div className="text-14 text-icon-text font-semibold">
                {t("legal")}
              </div>
              {tos.map((t, key: number) => (
                <button
                  key={key}
                  onClick={() => t.link && navigate(t.link)}
                  className="text-12 text-icon-text font-semibold hover:text-white">
                  {t.text}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full lg:grid grid-cols-1 content-start justify-items-start items-center gap-2 hidden">
          <div className="text-14 text-icon-text font-semibold">
            {t("contact")}
          </div>
          <div className="text-12 text-icon-text font-semibold">
            {t("contact_us_today_text")}
          </div>
          <div className="flex justify-start items-center gap-6 pt-2">
            {mediaIcons.map((mi, key: number) => (
              <button onClick={(e) => openLink(e, mi.link)} key={key} className="text-dark-icon-text hover:text-white">
                {mi.icon}
              </button>
            ))}
          </div>
          <div className="pt-2 hidden lg:inline">
            <COPYRIGHT className="fill-current" />
          </div>
        </div>
        <div className="w-full grid grid-cols-2 content-start justify-items-start items-center gap-2 lg:hidden">
          <div className="grid grid-cols-1 gap-1">
            <div className="text-14 text-icon-text font-semibold">
              {t("contact")}
            </div>
            <div className="text-12 text-icon-text font-semibold">
              {t("contact_us_today_text")}
            </div>
          </div>
          <div className="flex justify-start items-center gap-6">
            {mediaIcons.map((mi, key: number) => (
              <button onClick={(e) => openLink(e, mi.link)} key={key} className="text-dark-icon-text hover:text-white">
                {mi.icon}
              </button>
            ))}
          </div>
          <div className="pt-2 hidden lg:inline">
            <COPYRIGHT className="fill-current" />
          </div>
        </div>
        <div className="grid grid-cols-1 content-start justify-items-center gap-4 lg:hidden">
          <div className="text-8 text-icon-text text-center">
            <span onClick={(e) => openLink(e, HEYBETS_LINKS.SITE)} className="text-8 font-semibold text-icon-text underline cursor-pointer">
              Heybets.io
            </span>
            {" "}{t("is_owned")}
          </div>
          <div className="lg:pt-2">
            <COPYRIGHT className="fill-current" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
