import { ReactComponent as Logo } from "assets/icons/logo.svg"
import { useNavigate } from "react-router-dom"
import { URL } from "constants/routes"
import ModalLetsGetInTouch from "components/modals/ModalLetsGetInTouch"
import { useProps } from "contexts/PropsContext"
import FullLenghtButton from "components/buttons/FullLenghtButton"
import { useTranslation } from "react-i18next"
import ModalImage from "components/modals/ModalThankYou"

const NavMobile = () => {
  const {
    openLetsGetInTouch,
    setOpenLetsGetInTouch,
    openContactSoon,
    setOpenContactSoon
  } = useProps()
  const navigate = useNavigate()
  const { t } = useTranslation(["landing"])

  return (
    <div className={"w-screen h-16 px-4 fixed z-[110] top-0 left-0 flex justify-center items-center bg-main-blue"} >
      <div className="w-full flex justify-start items-center">
        <button onClick={() => navigate(URL.HOME)}>
          <Logo className="h-6" />
        </button>
      </div>
      <div className="w-full flex justify-end items-center">
        <div className="w-full">
          <FullLenghtButton text={t("become_an_affiliate")} handler={() => setOpenLetsGetInTouch(true)} buttonClassname="py-2" />
        </div>
      </div>
      <ModalLetsGetInTouch open={openLetsGetInTouch} handler={setOpenLetsGetInTouch} />
      <ModalImage open={openContactSoon} handler={setOpenContactSoon} />
    </div>
  )
}

export default NavMobile
