import { useProps } from "contexts/PropsContext"
import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

const ScrollToTop = () => {
  const location = useLocation()
  const { setPath } = useProps()

  useEffect(() => {
    window.scrollTo(0, 0)
    setPath(location.pathname)
  }, [location])

  return <></>
}

export default ScrollToTop
