import axios from "axios"
import { REQ } from "constants/api"
import { parseError } from "./error"

export const createPartnersContact = async (contactName: string, contactMethod: string, contactValue: string, contactPromote: string) => {
    const response = await axios({
        url: REQ.PARTNERS.AFFILIATE_CONTACT,
        method: "post",
        data: {
            contact_name: contactName,
            contact_method: contactMethod,
            contact_value: contactValue,
            contact_promote: contactPromote
        },
        withCredentials: true
      }).catch((error) => {
          parseError(error)
        })

    if (response?.data?.success) {
        return true
    }

    return false
}
