import WrapperPage from "components/wrappers/WrapperPage"
import { responsibleGamingText } from "constants/legal"
import { useTranslation } from "react-i18next"

const ResponsibleGaming = () => {
    const { t } = useTranslation(["landing"])
    return (
        <WrapperPage>
            <div className="w-full grid grid-cols-1">
                <div className="text-48 text-white">
                    {t("responsible_gaming")}
                </div>
                <div className="text-12 text-icon-text whitespace-pre-wrap">
                    {responsibleGamingText}
                </div>
            </div>
        </WrapperPage>
    )
}

export default ResponsibleGaming
